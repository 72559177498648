<script lang="ts" setup>
import type { HomeBanners } from "@/types";

const props = defineProps<{ banner: HomeBanners[number] }>();

const isGuest = useIsGuest();
const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open);

const handleClick = () => {
	if (isGuest.value || props.banner?.buttonLink === "/register/") {
		open("LazyOModalSignup");
		return;
	}

	if (!props.banner?.buttonLink) {
		return;
	}

	if (props.banner?.buttonLink) {
		if (props.banner?.buttonLink.includes("facebook")) {
			window.open(props.banner?.buttonLink, "_blank");
			return;
		}
		if (props.banner?.buttonLink.includes("game=")) {
			const slug = props.banner?.buttonLink.replace("game=", "");
			dispatchGAEvent({
				event: "click_button",
				button_name: "money_play",
				location: slug,
				form_name: "banner"
			});
			handleOpenGame(slug);
			return;
		}

		if (props.banner?.buttonLink.includes("openModal=")) {
			const slug = props.banner?.buttonLink.replace("openModal=", "");
			open(slug as keyof Modals);
			return;
		}

		navigateTo(props.banner?.buttonLink);
	}
};
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleClick"
	>
		<template #default>
			<AText v-if="banner.title" :size="24" :modifiers="['uppercase', 'semibold']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<AText v-if="banner.smallTitle" class="small-title" :size="20" :modifiers="['uppercase', 'italic', 'medium']">
				<p v-html="banner.smallTitle" />
			</AText>
			<MPrizeFund v-if="banner.subTitle" variant="entries" icon="12/secret-coins" :icon-size="28" :offset="1">
				<AText class="color-gray-50" :size="36" :modifiers="['bold']">
					<p v-html="banner.subTitle" />
				</AText>
			</MPrizeFund>
		</template>

		<template #actions>
			<AButton variant="primary" size="md" class="app-banner__btn">
				{{ banner.buttonText }}
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.small-title {
	margin: -8px 0 8px;

	@include media-breakpoint-down(lg) {
		margin: 4px 0;
	}
}
</style>
